import React from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Icon from '../Icon/Icon'
import ButtonComponent from '../Button/Button'

import { LinkOrButtonWrapper, Anchor } from './LinkOrButton.styled'

const LinkOrButton = props => {
	return (
		<LinkOrButtonWrapper>
			{props.type === 'link' || props.type === true ? (
				<Anchor to={props.url}>
					{props.text} <Icon width="1rem" height="1rem" color="primary" margin="0 0 0 10px" icon={faArrowRight} />
				</Anchor>
			) : (
				<ButtonComponent
					margin={props.margin}
					variant={props.variant ? props.variant : 'primary'}
					padding={props.padding}
					url={props.url}
					text={props.text}
					state={props.state}
				/>
			)}
		</LinkOrButtonWrapper>
	)
}

export default LinkOrButton
